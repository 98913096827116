import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl";
import CartSVG from "@/images/SVG/cart.svg"

const ProBanner = ({ banner_intro, setProbannerType, probannerType }) => {
    const { name, buy_link ,logo} = banner_intro
    const bannerRef = useRef()
    const ifPc = useBigScreen(768)
    const intl = useIntl()

    useEffect(() => {
        document.body.style.overflow = 'auto'
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    let lastScrollTop = 0;
    const handleScroll = () => {
        const currentScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollDistance = currentScrollTop - lastScrollTop
        lastScrollTop = currentScrollTop;

        if (scrollDistance > 0) {
            bannerRef.current.style.opacity = '0'
            bannerRef.current.style.transform = `translate(-50%,50px)`
            bannerRef.current.style.pointerEvents = 'none'
        } else {
            bannerRef.current.style.opacity = '1'
            bannerRef.current.style.transform = `translate(-50%,0)`
            bannerRef.current.style.pointerEvents = 'unset'

        }
    }
    return (

        <div className={style.banner} ref={bannerRef}>
            <div className={style.bannerTab}>
                {ifPc && <div className={style.bannerTabName}>
                    {logo?logo:name}
                </div>}

                <div className={style.bannerTabBtn}>
                    <div className={style.bannerTabBtnItem} style={{ color: probannerType == 'overview' ? '#000' : "rgba(0,0,0,0.4)" }}
                        onClick={() =>{ setProbannerType('overview');
                        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                            custom: {
                                module_id: 20072
                            }
                        });
                    }}
                    >
                     {intl.formatMessage({ id: "probanner_overview" })}
                    </div>
                    <div className={style.bannerTabBtnItem} style={{ color: probannerType == 'specs' ? '#000' : "rgba(0,0,0,0.4)" }}
                        onClick={() => {setProbannerType('specs');
                        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                            custom: {
                                module_id: 20073
                            }
                        });
                    }}
                    >
                       {intl.formatMessage({ id: "probanner_specs" })}
                    </div>
                </div>
            </div>
            {!!buy_link && <div className={style.bannerBuy} onClick={() => {
                window.open(buy_link)
            }}>
                <CartSVG />
            </div>}

        </div>
    )
}

export default ProBanner